import {TableTransactionFieldsFragment, gql} from '../graphql';
import {useTags} from './tag-hooks';
import {useQuery} from '@apollo/client';
import {DateTime} from 'luxon';
import {formatIsoDate} from 'shared/src/datetime';

const GET_TRANSACTIONS = gql(/* GraphQL */ `
	query GetTransactionsForCommonHook(
		$startDate: String
		$endDate: String
		$categoryIds: [Int!]
		$tagIds: [Int!]
		$excludedTagIds: [Int!]
	) {
		transactions(
			startDate: $startDate
			endDate: $endDate
			categoryIds: $categoryIds
			tagIds: $tagIds
			excludedTagIds: $excludedTagIds
		) {
			...TableTransactionFields
		}
	}
`);

interface TransactionQuery {
	startDate?: DateTime;
	endDate?: DateTime;
	categoryIds?: number[];
	tagIds?: number[];
	excludedTagIds?: number[];
	filter?: (transaction: TableTransactionFieldsFragment) => boolean;
	skip?: boolean;
}

interface TransactionsResult {
	transactions: TableTransactionFieldsFragment[] | null;
	refetch: () => void;
}

export const useTransactions = (query: TransactionQuery): TransactionsResult => {
	const {data: {transactions: rawTransactions} = {}, refetch} = useQuery(GET_TRANSACTIONS, {
		variables: {
			startDate: query.startDate ? formatIsoDate(query.startDate) : null,
			endDate: query.endDate ? formatIsoDate(query.endDate) : null,
			categoryIds: query.categoryIds,
			tagIds: query.tagIds,
			excludedTagIds: query.excludedTagIds,
		},
		skip: query.skip,
	});

	let transactions = rawTransactions ?? null;
	if (transactions && query.filter) {
		transactions = transactions.filter(query.filter);
	}

	return {
		transactions,
		refetch,
	};
};

interface GenericQuery {
	startDate: DateTime;
	endDate: DateTime;
}

export const useIncomeTransactions = ({startDate, endDate}: GenericQuery): TransactionsResult => {
	const {tags} = useTags();
	const excludedIncomeTag = tags?.find((tag) => tag.internalId === 'excludedIncome');

	return useTransactions({
		startDate,
		endDate,
		excludedTagIds: [excludedIncomeTag?.id].filter((id) => id !== undefined),
		filter: (transaction: TableTransactionFieldsFragment) => {
			return transaction.amount > 0;
		},
		skip: !excludedIncomeTag,
	});
};

export const useExpenseTransactions = ({startDate, endDate}: GenericQuery): TransactionsResult => {
	const {tags} = useTags();
	const excludedExpensesTag = tags?.find((tag) => tag.internalId === 'excludedExpenses');

	return useTransactions({
		startDate,
		endDate,
		excludedTagIds: [excludedExpensesTag?.id].filter((id) => id !== undefined),
		filter: (transaction: TableTransactionFieldsFragment) => {
			return transaction.amount < 0;
		},
		skip: !excludedExpensesTag,
	});
};

export const useSalesTaxCollectedTransactions = ({
	startDate,
	endDate,
}: GenericQuery): TransactionsResult => {
	const {tags} = useTags();
	const salesTaxCollectedTag = tags?.find((tag) => tag.internalId === 'excludedIncomeSalesTax');

	return useTransactions({
		startDate,
		endDate,
		tagIds: [salesTaxCollectedTag?.id].filter((id) => id !== undefined),
		skip: !salesTaxCollectedTag,
	});
};
