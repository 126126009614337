import {gql} from '../../graphql';
import {useQuery} from '@apollo/client';
import {Select, type TypeSelectProps} from '@sproutsocial/racine';
import {SyntheticEvent, useCallback, useId} from 'react';

type ProductPickerProps = Omit<
	TypeSelectProps,
	'id' | 'name' | 'value' | 'onChange' | 'children'
> & {
	id?: string;
	name?: string;
	selectedProductId: number | null;
	selectedProductVariantId: number | null;
	onChange: (
		event: SyntheticEvent<HTMLSelectElement>,
		productId: number | null,
		productVariantId: number | null,
	) => void;
};

const GET_PRODUCTS = gql(/* GraphQL */ `
	query GetProductsForPicker {
		products {
			id
			name
			variants {
				id
				description
			}
		}
	}
`);

const EMPTY_VALUE = 'null';

const ProductPicker = ({
	selectedProductId,
	selectedProductVariantId,
	onChange,
	...rest
}: ProductPickerProps) => {
	const id = useId();

	const {data: {products} = {}} = useQuery(GET_PRODUCTS);

	const onValueSelected = useCallback(
		(event: SyntheticEvent<HTMLSelectElement>) => {
			if (event.currentTarget.value === EMPTY_VALUE) {
				onChange(event, null, null);
			} else {
				const [productId, variantId] = event.currentTarget.value.split('-');

				onChange(event, parseInt(productId, 10), variantId ? parseInt(variantId, 10) : null);
			}
		},
		[onChange],
	);

	return (
		<Select
			{...rest}
			id={rest.id ?? id}
			name={rest.name ?? id}
			value={buildProductId(selectedProductId, selectedProductVariantId) || EMPTY_VALUE}
			onChange={onValueSelected}
		>
			<option value={EMPTY_VALUE}>(None)</option>
			{products?.map((product) => {
				if (!product.variants?.length) {
					return <option key={product.id} value={product.id + ''} children={product.name} />;
				} else {
					return (
						<optgroup key={'group:' + product.id} label={product.name}>
							<option value={product.id + ''} children={product.name} />
							{product.variants.map((variant) => (
								<option
									key={buildProductId(product.id, variant.id)}
									value={buildProductId(product.id, variant.id)}
									children={`${product.name} - ${variant.description}`}
								/>
							))}
						</optgroup>
					);
				}
			})}
		</Select>
	);
};

const buildProductId = (productId: number | null, variantId: number | null): string => {
	return [productId, variantId].filter(Boolean).join('-');
};

export default ProductPicker;
