import {formatTransactionCategory} from '../formatters';
import {type TableTransactionFieldsFragment} from '../graphql';
import {stringify} from 'csv-stringify/browser/esm/sync';
import {saveAs} from 'file-saver';

const CSV_HEADERS = ['Date', 'Payment Account', 'Payee', 'Category', 'Memo', 'Amount', 'Tags'];

export const downloadTransactionCsv = (
	transactions: TableTransactionFieldsFragment[],
	filename: string,
): void => {
	const blob = buildTransactionCsv(transactions);

	saveAs(blob, filename);
};

export const buildTransactionCsv = (transactions: TableTransactionFieldsFragment[]): Blob => {
	const csvData = transactions.map((transaction) => {
		return [
			transaction.date,
			transaction.paymentAccount.name,
			transaction.payee,
			formatTransactionCategory(transaction.category),
			transaction.memo,
			(transaction.amount / 100).toFixed(2),
			transaction.tags.map((tag) => tag.name).join(', '),
		];
	});

	const csvString = stringify([CSV_HEADERS, ...csvData]);

	return new Blob([csvString], {type: 'text/csv;charset=utf-8'});
};
