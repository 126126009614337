import {gql} from '../../graphql';
import TransactionsTable from '../transactions/TransactionsTable';
import {useQuery} from '@apollo/client';
import React from 'react';

const GET_PROJECT = gql(/* GraphQL */ `
	query GetProjectTransactions($id: Int!) {
		project(id: $id) {
			id
			transactions {
				id
				date
				amount
				memo
				payee
				paymentAccount {
					id
					name
				}
				tags {
					id
					name
					color
				}
				projects {
					id
					name
				}
				category {
					id
					name
					hidden
					priority
					categoryGroup {
						id
						name
					}
				}
				sale {
					id
				}
			}
		}
	}
`);

interface ProjectTransactionsProps {
	projectId: number;
}

const ProjectTransactions = ({projectId}: ProjectTransactionsProps) => {
	const {data: {project} = {}} = useQuery(GET_PROJECT, {
		variables: {
			id: projectId,
		},
	});

	if (!project) {
		return null;
	}

	return <TransactionsTable id={`project-${project.id}`} transactions={project.transactions} />;
};

export default ProjectTransactions;
