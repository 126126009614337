import {SyntheticEvent, useCallback, useMemo, useState} from 'react';

interface LocalStorageStateConfig<T> {
	localStorageKey: string;
	defaultValue: T;
	serialize?: (value: T) => string | null;
	deserialize?: (json: string) => T;
}

export const useLocalStorageState = <T>({
	localStorageKey,
	defaultValue,
	serialize = JSON.stringify,
	deserialize = JSON.parse,
}: LocalStorageStateConfig<T>): [T, (value: T) => void] => {
	const [value, rawSetValue] = useState(() => {
		const storedJson = localStorage.getItem(localStorageKey);

		return storedJson ? deserialize(storedJson) : defaultValue;
	});

	const setValue = useCallback(
		(newValue: T) => {
			rawSetValue(newValue);

			const serializedValue = serialize(newValue);

			if (serializedValue) {
				localStorage.setItem(localStorageKey, serializedValue);
			}
		},
		[localStorageKey, serialize, rawSetValue],
	);

	return [value, setValue];
};

export const useSetState = <V>(initialValues?: V[] | Set<V>): Set<V> => {
	const [set] = useState(new Set<V>(Array.from(initialValues ?? [])));

	const [sequence, setSequence] = useState(0);
	const triggerUpdate = useCallback(() => {
		setSequence(sequence + 1);
	}, [sequence, setSequence]);

	return useMemo<Set<V>>(() => {
		return new Proxy(set, {
			get(target, prop, proxy) {
				const originalValue = Reflect.get(target, prop, target);

				if (typeof originalValue === 'function') {
					if (prop === 'add') {
						return (value: V): Set<V> => {
							set.add(value);

							triggerUpdate();

							return set;
						};
					} else if (prop === 'clear') {
						return (): Set<V> => {
							set.clear();

							triggerUpdate();

							return set;
						};
					} else if (prop === 'delete') {
						return (key: V): Set<V> => {
							set.delete(key);

							triggerUpdate();

							return set;
						};
					}

					return originalValue.bind(set);
				}

				return originalValue;
			},
		});
	}, [set, triggerUpdate]);
};

export const useKeyValueState = <K, V>(initializer?: () => Map<K, V>): Map<K, V> => {
	const [map] = useState(initializer ?? new Map<K, V>());

	const [sequence, setSequence] = useState(0);
	const triggerUpdate = useCallback(() => {
		setSequence(sequence + 1);
	}, [sequence, setSequence]);

	return useMemo<Map<K, V>>(() => {
		return new Proxy(map, {
			get(target, prop, proxy) {
				const originalValue = Reflect.get(target, prop, target);

				if (typeof originalValue === 'function') {
					if (prop === 'set') {
						return (key: K, value: V): Map<K, V> => {
							map.set(key, value);

							triggerUpdate();

							return map;
						};
					} else if (prop === 'delete') {
						return (key: K): Map<K, V> => {
							map.delete(key);

							triggerUpdate();

							return map;
						};
					}

					return originalValue.bind(map);
				}

				return originalValue;
			},
		});
	}, [map, triggerUpdate]);
};

// Can pass the mutation function directly to Input or TextArea as the `onChange` prop.
type TextInputOnChange = (
	event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement> | null,
	newValue?: string,
) => void;
export const useTextInputState = (
	initialValue: string | (() => string) = '',
): [string, TextInputOnChange] => {
	const [value, setValue] = useState<string>(initialValue);

	const onChange = useCallback(
		(event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement> | null, newValue?: string) => {
			setValue(newValue ?? event?.currentTarget.value ?? '');
		},
		[],
	);

	return [value, onChange];
};
