import {useReportsContext} from '../../context/reports-context';
import Layout from '../Layout';
import ReportingPeriodPicker from '../forms/ReportingPeriodPicker';
import {Select} from '@sproutsocial/racine';
import {DateTime} from 'luxon';
import {ReactNode, SyntheticEvent, useCallback, useMemo} from 'react';

interface ReportsLayoutProps {
	title: ReactNode;
	datePickerType?: 'daily' | 'yearly';
	children: ReactNode;
}

const ReportsLayout = ({title, datePickerType = 'daily', children}: ReportsLayoutProps) => {
	const {reportingPeriod} = useReportsContext();

	const validYears = useMemo(() => {
		const years = [];

		for (let year = 2023; year <= DateTime.now().year; ++year) {
			years.push(year);
		}

		return years;
	}, []);

	const onYearChange = useCallback(
		(event: SyntheticEvent<HTMLSelectElement>) => {
			const nextYear = parseInt(event.currentTarget.value, 10);

			reportingPeriod.onChange(
				DateTime.fromISO(`${nextYear}-01-01`),
				DateTime.fromISO(`${nextYear}-12-31`),
			);
		},
		[reportingPeriod],
	);

	let currentYear = validYears[validYears.length - 1];

	// Get the current year from the dates, or update the dates if it's not a valid year range
	if (datePickerType === 'yearly') {
		if (isFullYear(reportingPeriod.startDate, reportingPeriod.endDate)) {
			currentYear = reportingPeriod.startDate.year;
		} else {
			reportingPeriod.onChange(
				DateTime.fromISO(`${currentYear}-01-01`),
				DateTime.fromISO(`${currentYear}-12-31`),
			);
		}
	}

	return (
		<Layout>
			<Layout.Header title={title}>
				{datePickerType === 'daily' && (
					<ReportingPeriodPicker
						startDate={reportingPeriod.startDate}
						endDate={reportingPeriod.endDate}
						onChange={reportingPeriod.onChange}
					/>
				)}
				{datePickerType === 'yearly' && (
					<Select
						id='reporting-yearly-date-picker'
						name='reporting-yearly-date-picker'
						value={currentYear}
						onChange={onYearChange}
					>
						{validYears.map((year) => (
							<option key={year} value={year}>
								{year}
							</option>
						))}
					</Select>
				)}
			</Layout.Header>
			<Layout.Body children={children} />
		</Layout>
	);
};

const isFullYear = (startDate: DateTime, endDate: DateTime): boolean => {
	return (
		startDate.hasSame(startDate.startOf('year') as any, 'day') &&
		endDate.hasSame(endDate.endOf('year') as any, 'day') &&
		startDate.year === endDate.year
	);
};

export default ReportsLayout;
