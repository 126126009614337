import {Product, gql} from '../../graphql';
import Layout from '../Layout';
import ProductEditModal from './ProductEditModal';
import {useQuery} from '@apollo/client';
import {Box, Button, Icon, Text} from '@sproutsocial/racine';
import {SyntheticEvent, useCallback, useState} from 'react';

const GET_PRODUCTS = gql(/* GraphQL */ `
	query GetProductsForManagementPage {
		products {
			id
			name
			variants {
				id
				description
			}
		}
	}
`);

const Products = () => {
	const {data: {products} = {}, refetch: refetchProducts} = useQuery(GET_PRODUCTS);

	const [isEditing, setIsEditing] = useState(false);
	const [editingProduct, setEditingProduct] = useState<Product>();

	const onCreateProduct = useCallback(() => {
		setIsEditing(true);
	}, []);

	const onEditProduct = useCallback(
		(event: SyntheticEvent<HTMLElement>) => {
			const productId = parseInt(event.currentTarget.dataset['productId']!, 10);

			setIsEditing(true);
			setEditingProduct(products!.find((product) => product.id === productId));
		},
		[products],
	);

	const onDoneEditing = useCallback(
		(didMakeChanges: boolean) => {
			setIsEditing(false);
			setEditingProduct(undefined);

			if (didMakeChanges) {
				refetchProducts();
			}
		},
		[refetchProducts],
	);

	return (
		<Layout>
			<Layout.Header title='Products'>
				<Button appearance='primary' onClick={onCreateProduct}>
					<Icon name='cart-plus-solid' mr='space.300' /> New
				</Button>
			</Layout.Header>
			<Layout.Body>
				<Box border={500} borderRadius='outer' borderColor='container.border.base' bg='white'>
					<ul style={{listStyleType: 'none'}}>
						{products?.map((product) => (
							<li key={product.id}>
								<Box display='flex' alignItems='center'>
									<Text fontSize={200} fontWeight='semibold' color='text.headline'>
										{product.name}
									</Text>
									<Icon
										name='pencil-to-square-outline'
										ml='space.400'
										color='button.primary.background.base'
										cursor='pointer'
										onClick={onEditProduct}
										data-product-id={product.id}
									/>
								</Box>

								{product.variants && product.variants.length > 0 && (
									<ul style={{listStyleType: 'disc'}}>
										{product.variants.map((variant) => (
											<li key={variant.id}>
												<Text fontSize={200} color='text.body'>
													{variant.description}
												</Text>
											</li>
										))}
									</ul>
								)}
							</li>
						))}
					</ul>
				</Box>

				{isEditing && <ProductEditModal product={editingProduct} onClose={onDoneEditing} />}
			</Layout.Body>
		</Layout>
	);
};

export default Products;
