import {type NewProjectInput, ProjectStatus, gql} from '../../graphql';
import {useTextInputState} from '../../hooks';
import CustomerPicker from '../CustomerPicker';
import DatePicker from '../DatePicker';
import Layout from '../Layout';
import MoneyInput from '../forms/MoneyInput';
import ProjectStatusPicker from '../forms/ProjectStatusPicker';
import {useMutation} from '@apollo/client';
import {Box, FormField, Icon, Input, LoaderButton} from '@sproutsocial/racine';
import {DateTime} from 'luxon';
import {useCallback, useMemo, useState} from 'react';
import {formatIsoDate} from 'shared/src/datetime';

const CREATE_PROJECT = gql(/* GraphQL */ `
	mutation CreateProject($newProject: NewProjectInput) {
		createProject(project: $newProject) {
			id
		}
	}
`);

const NewProject = () => {
	const [name, onNameChange] = useTextInputState();
	const [customerId, onCustomerIdChange] = useState<string | null>(null);
	const [description, onDescriptionChange] = useTextInputState();
	const [startDate, onStartDateChange] = useState<DateTime>(DateTime.now());
	const [cost, onCostChange] = useState<number | null>(null);
	const [deposit, onDepositChange] = useState<number | null>(null);
	const [status, onStatusChange] = useState<ProjectStatus>(ProjectStatus.Upcoming);

	const isValid = useMemo<boolean>(() => {
		if (!name.trim() || !description.trim()) {
			return false;
		}

		if (!customerId) {
			return false;
		}

		if (startDate < DateTime.fromISO('2020-01-01')) {
			return false;
		}

		return true;
	}, [name, description, customerId, startDate]);

	const [createProject, {loading: isCreating}] = useMutation(CREATE_PROJECT);

	const onCreate = useCallback(async () => {
		const newProject: NewProjectInput = {
			name: name.trim(),
			description: description.trim(),
			startDate: formatIsoDate(startDate),
			cost,
			deposit,
			customerId: customerId!,
			status,
		};

		const {data} = await createProject({
			variables: {
				newProject,
			},
		});

		window.location.href = `/projects/${data?.createProject.id}`;
	}, [createProject, name, description, startDate, customerId, cost, deposit, status]);

	return (
		<Layout>
			<Layout.Header title='New Project'>
				<LoaderButton
					appearance='primary'
					isLoading={isCreating}
					onClick={onCreate}
					disabled={!isValid}
				>
					<Icon name='plus' mr='space.300' /> Create
				</LoaderButton>
			</Layout.Header>

			<Layout.Body>
				<Box maxWidth={300}>
					<FormField label='Status'>
						{(props) => (
							<ProjectStatusPicker {...props} status={status} onChange={onStatusChange} />
						)}
					</FormField>
					<FormField label='Name'>
						{(props) => (
							<Input {...props} name='projectName' value={name} onChange={onNameChange} required />
						)}
					</FormField>
					<FormField label='Customer'>
						{(props) => (
							<CustomerPicker
								{...props}
								selectedCustomerId={customerId}
								onChange={onCustomerIdChange}
							/>
						)}
					</FormField>
				</Box>
				<Box maxWidth={600}>
					<FormField label='Description'>
						{(props) => (
							<Input
								{...props}
								name='description'
								value={description}
								onChange={onDescriptionChange}
								required
							/>
						)}
					</FormField>
				</Box>
				<Box maxWidth={300}>
					<FormField label='Start Date'>
						{(props) => <DatePicker {...props} value={startDate} onChange={onStartDateChange} />}
					</FormField>
					<FormField label='Cost'>
						{(props) => (
							<MoneyInput {...props} name='cost' value={cost} onChange={onCostChange} required />
						)}
					</FormField>
					<FormField label='Deposit'>
						{(props) => (
							<MoneyInput
								{...props}
								name='deposit'
								value={deposit}
								onChange={onDepositChange}
								required
							/>
						)}
					</FormField>
				</Box>
			</Layout.Body>
		</Layout>
	);
};

export default NewProject;
