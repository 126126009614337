import {useReportsContext} from '../../context/reports-context';
import {formatCurrency} from '../../formatters';
import {gql} from '../../graphql';
import Table from '../Table';
import ReportsLayout from './ReportsLayout';
import {useQuery} from '@apollo/client';
import {Text} from '@sproutsocial/racine';
import {useCallback, useMemo} from 'react';
import {formatIsoDate} from 'shared/src/datetime';

const GET_SALES = gql(/* GraphQL */ `
	query GetSalesForReport($startDate: String!, $endDate: String!) {
		sales(startDate: $startDate, endDate: $endDate) {
			id
			note
			transaction {
				id
				memo
				amount
			}
			items {
				id
				receivedAmount
				faceValue
				taxAmount
				quantity
				note
				product {
					id
					name
				}
				productVariant {
					id
					description
				}
			}
		}
	}
`);

const HEADERS = [
	{id: 'name', content: 'Item'},
	{id: 'note', content: 'Description'},
	{id: 'quantity', content: 'Quantity'},
	{id: 'faceValue', content: 'Face Value'},
	{id: 'receivedAmount', content: 'Amount Received'},
	{id: 'taxAmount', content: 'Taxes Collected'},
];

const SalesReport = () => {
	const {reportingPeriod} = useReportsContext();

	const {data: {sales} = {}} = useQuery(GET_SALES, {
		variables: {
			startDate: formatIsoDate(reportingPeriod.startDate),
			endDate: formatIsoDate(reportingPeriod.endDate),
		},
	});

	const totalsRow = useMemo(() => {
		const items = (sales ?? []).flatMap((sale) => sale.items);

		const {sold, received, tax} = items.reduce(
			({sold, received, tax}, item) => {
				return {
					sold: sold + (item.faceValue || item.receivedAmount),
					received: received + item.receivedAmount,
					tax: tax + (item.taxAmount || 0),
				};
			},
			{sold: 0, received: 0, tax: 0},
		);

		return {
			id: 'totals',
			cells: [
				null,
				null,
				null,
				<Text fontWeight='semibold'>{formatCurrency(sold)}</Text>,
				<Text fontWeight='semibold'>{formatCurrency(received)}</Text>,
				<Text fontWeight='semibold'>{formatCurrency(tax)}</Text>,
			],
		};
	}, [sales]);

	const generateRow = useCallback((sale: NonNullable<typeof sales>[0]) => {
		return sale.items.map((item) => ({
			id: item.id,
			cells: [
				item.product &&
					`${item.product.name} ${item.productVariant ? ` - ${item.productVariant.description}` : ''}`,
				item.note,
				item.quantity || null,
				formatCurrency(item.faceValue || item.receivedAmount),
				formatCurrency(item.receivedAmount),
				item.taxAmount ? formatCurrency(item.taxAmount) : null,
			],
		}));
	}, []);

	return (
		<ReportsLayout title='Sales Report'>
			<Table
				id='income-report'
				head={HEADERS}
				fixedRow={totalsRow}
				items={sales ?? []}
				generateRow={generateRow}
			/>
		</ReportsLayout>
	);
};

export default SalesReport;
