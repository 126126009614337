import {formatCurrency} from '../../formatters';
import {type NewSaleInput, gql} from '../../graphql';
import {useTextInputState} from '../../hooks';
import Table from '../Table';
import ProductPicker from '../forms/ProductPicker';
import {useMutation, useQuery} from '@apollo/client';
import {Box, Button, Icon, Input, Link, LoaderButton, Modal, Text} from '@sproutsocial/racine';
import {SyntheticEvent, useCallback, useMemo, useState} from 'react';

interface CreateSaleModalProps {
	transactionId: number;
	onClose: (saleCreated?: boolean) => void;
}

const TABLE_HEADERS = [
	{id: 'product', content: 'Product'},
	{id: 'note', content: 'Note'},
	{id: 'receivedAmount', content: 'Received Amount'},
	{id: 'saleAmount', content: 'Face Value'},
	{id: 'taxAmount', content: 'Tax Amount'},
	{id: 'quantity', content: 'Quantity'},
	{id: 'actions', content: null},
];

interface TableItem {
	id: string;
	note: string;
	receivedAmount: string;
	faceValue: string;
	taxAmount: string;
	quantity: string;
	productId: number | null; // null at the start
	productVariantId: number | null;
}

const GET_TRANSACTION = gql(/* GraphQL */ `
	query GetTransactionForSale($id: Int!) {
		transaction(id: $id) {
			id
			memo
			payee
			amount
			paymentAccount {
				name
			}
		}
	}
`);

const CREATE_SALE = gql(/* GraphQL */ `
	mutation CreateSale($newSale: NewSaleInput!) {
		createSale(newSale: $newSale) {
			id
		}
	}
`);

const CreateSaleModal = ({transactionId, onClose}: CreateSaleModalProps) => {
	const {data: {transaction} = {}} = useQuery(GET_TRANSACTION, {
		variables: {
			id: transactionId,
		},
	});

	const [note, onNoteChanged] = useTextInputState('');

	const [items, setItems] = useState<TableItem[]>([getNewItem()]);

	const onRemoveRow = useCallback(
		(event: SyntheticEvent<HTMLElement>) => {
			const removalId = event.currentTarget.dataset['itemId']!;

			setItems(items.filter((item) => item.id !== removalId));
		},
		[items],
	);

	const onAddNewRow = useCallback(() => {
		setItems([...items, getNewItem()]);
	}, [items]);

	const onProductChanged = useCallback(
		(
			event: SyntheticEvent<HTMLSelectElement>,
			productId: number | null,
			productVariantId: number | null,
		) => {
			const itemId = event.currentTarget.parentElement!.dataset['itemId'];

			setItems(
				items.map((item) => {
					if (item.id === itemId) {
						return {
							...item,
							productId,
							productVariantId,
						};
					} else {
						return item;
					}
				}),
			);
		},
		[items],
	);

	const onInputFieldChanged = useCallback(
		(event: SyntheticEvent<HTMLInputElement>) => {
			const itemId = event.currentTarget.parentElement!.dataset['itemId'];
			const fieldName: keyof TableItem = event.currentTarget.name as keyof TableItem;

			setItems(
				items.map((item) => {
					if (item.id === itemId) {
						return {
							...item,
							[fieldName]: event.currentTarget.value,
						};
					} else {
						return item;
					}
				}),
			);
		},
		[items],
	);

	const saveEnabled = useMemo(() => {
		if (!transaction) {
			return false;
		}

		for (const item of items) {
			// These have to be specified in some manner
			if (!item.receivedAmount.trim() || !item.productId) {
				return false;
			}

			// Each item must have a valid price
			const receivedAmount = parseDollarAmount(item.receivedAmount);
			if (receivedAmount < 0 || receivedAmount > transaction.amount) {
				return false;
			}

			const taxAmount = parseDollarAmount(item.taxAmount || '0');
			if (taxAmount < 0 || taxAmount > receivedAmount) {
				return false;
			}
		}

		return true;
	}, [transaction, items]);

	const [createSale, {loading: isCreating}] = useMutation(CREATE_SALE);
	const onSave = useCallback(async () => {
		const newSale: NewSaleInput = {
			note,
			transactionId,
			items: items.map((item) => ({
				receivedAmount: parseDollarAmount(item.receivedAmount),
				faceValue: parseDollarAmount(item.faceValue || '') || null,
				taxAmount: parseDollarAmount(item.taxAmount || '') || null,
				quantity: parseInt(item.quantity || '0', 10) || null,
				note: item.note,
				productId: item.productId,
				productVariantId: item.productVariantId,
			})),
		};

		await createSale({
			variables: {newSale},
		});

		onClose(true);
	}, [transactionId, onClose, note, items, createSale]);

	const onCancel = useCallback(() => onClose(), [onClose]);

	const generateRow = useCallback(
		(item: TableItem) => ({
			id: item.id,
			cells: [
				<ProductPicker
					selectedProductId={item.productId}
					selectedProductVariantId={item.productVariantId}
					onChange={onProductChanged}
					size='small'
					data-item-id={item.id}
				/>,
				<Input
					id='note'
					name='note'
					value={item.note}
					onChange={onInputFieldChanged}
					size='small'
					data-item-id={item.id}
				/>,
				<Input
					id='receivedAmount'
					name='receivedAmount'
					type='number'
					value={String(item.receivedAmount || '')}
					onChange={onInputFieldChanged}
					size='small'
					style={{maxWidth: 60}}
					inputProps={{min: 0}}
					data-item-id={item.id}
				/>,
				<Input
					id='faceValue'
					name='faceValue'
					type='number'
					value={String(item.faceValue)}
					onChange={onInputFieldChanged}
					size='small'
					style={{maxWidth: 60}}
					inputProps={{min: 0}}
					data-item-id={item.id}
				/>,
				<Input
					id='taxAmount'
					name='taxAmount'
					type='number'
					value={String(item.taxAmount || '')}
					onChange={onInputFieldChanged}
					size='small'
					style={{maxWidth: 60}}
					inputProps={{min: 0}}
					data-item-id={item.id}
				/>,
				<Input
					id='quantity'
					name='quantity'
					type='number'
					value={String(item.quantity || '')}
					onChange={onInputFieldChanged}
					size='small'
					style={{maxWidth: 60}}
					inputProps={{min: 0}}
					data-item-id={item.id}
				/>,
				<Box display='flex'>
					{items.length > 1 && (
						<Icon name='trash-solid' onClick={onRemoveRow} data-item-id={item.id} />
					)}
				</Box>,
			],
		}),
		[items, onRemoveRow, onInputFieldChanged, onProductChanged],
	);

	return (
		<Modal isOpen onClose={onClose} label='Create Sale' closeButtonLabel='Close'>
			<Modal.Header title='Create Sale' />
			<Modal.Content>
				{transaction && (
					<Text as='div' fontSize='200' mb='space.400'>
						<Box>
							<Text>
								Creating sale for <em>{transaction.memo}</em>
							</Text>

							<Box mb='space.200'>
								<Text fontWeight='semibold'>Amount received:&nbsp;</Text>
								{formatCurrency(transaction.amount)}
							</Box>
							<Box display='flex' alignItems='center'>
								<Text fontWeight='semibold'>Note:&nbsp;</Text>
								<Input id='note' name='note' value={note} onChange={onNoteChanged} size='small' />
							</Box>
						</Box>
					</Text>
				)}

				<Table
					id='create-sale'
					border={false}
					head={TABLE_HEADERS}
					items={items}
					generateRow={generateRow}
				/>

				<Link mt='space.300' onClick={onAddNewRow}>
					Add New Item
				</Link>
			</Modal.Content>
			<Modal.Footer display='flex' justifyContent='flex-end'>
				<Button onClick={onCancel} mr='space.300'>
					Cancel
				</Button>
				<LoaderButton
					appearance='primary'
					disabled={!saveEnabled}
					isLoading={isCreating}
					onClick={onSave}
				>
					Create
				</LoaderButton>
			</Modal.Footer>
		</Modal>
	);
};

const parseDollarAmount = (s: string) => Math.floor((parseFloat(s || '0') || 0) * 100);

const getNewItem = (): TableItem => ({
	id: crypto.randomUUID(),
	faceValue: '',
	receivedAmount: '',
	taxAmount: '',
	quantity: '',
	note: '',
	productId: null,
	productVariantId: null,
});

export default CreateSaleModal;
