import {type Product, gql} from '../../graphql';
import {useTextInputState} from '../../hooks';
import {useMutation} from '@apollo/client';
import {Box, Button, Icon, Input, LoaderButton, Modal, Text} from '@sproutsocial/racine';
import {type SyntheticEvent, useCallback, useMemo, useState} from 'react';
import {keyBy} from 'shared/src/map';

interface ProductEditModalProps {
	product?: Product;
	onClose: (didMakeUpdates: boolean) => void;
}

const CREATE_PRODUCT = gql(/* GraphQL */ `
	mutation CreateProduct($newProduct: NewProduct!) {
		createProduct(newProduct: $newProduct) {
			id
		}
	}
`);

const CREATE_VARIANT = gql(/* GraphQL */ `
	mutation CreateProductVariant($productId: Int!, $newVariant: NewProductVariant!) {
		createProductVariant(productId: $productId, newVariant: $newVariant) {
			id
		}
	}
`);

const UPDATE_PRODUCT = gql(/* GraphQL */ `
	mutation UpdateProduct($updatedProduct: UpdatedProduct!) {
		updateProduct(updatedProduct: $updatedProduct)
	}
`);

const UPDATE_VARIANT = gql(/* GraphQL */ `
	mutation UpdateVariant($updatedVariant: UpdatedProductVariant!) {
		updateProductVariant(updatedVariant: $updatedVariant)
	}
`);

const ProductEditModal = ({product: existingProduct, onClose}: ProductEditModalProps) => {
	const [name, setName] = useTextInputState(existingProduct?.name ?? '');
	const [variants, _setVariants] = useState<{id: string; description: string; isNew: boolean}[]>(
		() => {
			if (existingProduct?.variants?.length) {
				return [
					...existingProduct?.variants?.map((variant) => ({
						id: variant.id + '',
						description: variant.description,
						isNew: false,
					})),
					{
						id: crypto.randomUUID(),
						description: '',
						isNew: true,
					},
				];
			} else {
				return [
					{
						id: crypto.randomUUID(),
						description: '',
						isNew: true,
					},
				];
			}
		},
	);

	const setVariants = useCallback((newVariants: typeof variants) => {
		// Make sure the last variant is always a "new" variant
		if (newVariants[newVariants.length - 1].description.trim()) {
			newVariants.push({
				id: crypto.randomUUID(),
				description: '',
				isNew: true,
			});
		}

		_setVariants(newVariants);
	}, []);

	const onUpdateVariant = useCallback(
		(event: SyntheticEvent<HTMLInputElement>) => {
			const variantId = event.currentTarget.parentElement!.dataset['variantId']!;

			const newVariants = variants.map((variant) => {
				if (variant.id === variantId) {
					return {
						...variant,
						description: event.currentTarget.value,
					};
				} else {
					return variant;
				}
			});

			setVariants(newVariants);
		},
		[variants, setVariants],
	);

	const onRemoveVariant = useCallback(
		(event: SyntheticEvent<HTMLElement>) => {
			const variantId = event.currentTarget.dataset['variantId']!;

			setVariants(variants.filter((variant) => variant.id !== variantId));
		},
		[variants, setVariants],
	);

	const saveEnabled = useMemo(() => {
		if (!name.trim()) {
			return false;
		}

		for (const variant of variants) {
			if (!variant.description.trim() && !variant.isNew) {
				return false;
			}
		}

		return true;
	}, [name, variants]);

	const onCancel = useCallback(() => {
		onClose(false);
	}, [onClose]);

	const [createProduct, {loading: isCreatingProduct}] = useMutation(CREATE_PRODUCT);
	const [createVariant, {loading: isCreatingVariant}] = useMutation(CREATE_VARIANT);
	const [updateProduct, {loading: isUpdatingProduct}] = useMutation(UPDATE_PRODUCT);
	const [updateVariant, {loading: isUpdatingVariant}] = useMutation(UPDATE_VARIANT);

	const isLoading =
		isCreatingProduct || isCreatingVariant || isUpdatingProduct || isUpdatingVariant;

	const onSave = useCallback(async () => {
		if (existingProduct) {
			if (name.trim() !== existingProduct.name) {
				await updateProduct({
					variables: {
						updatedProduct: {
							id: existingProduct.id,
							name: name.trim(),
						},
					},
				});
			}

			// Update existing variants if they changed
			if (existingProduct.variants?.length) {
				const existingVariantDescriptions = keyBy(
					existingProduct.variants ?? [],
					(variant) => variant.id,
					(variant) => variant.description,
				);

				await Promise.all(
					variants
						.filter((variant) => !variant.isNew)
						.filter(
							(variant) =>
								variant.description.trim() !==
								existingVariantDescriptions.get(parseInt(variant.id, 10)),
						)
						.map((variant) =>
							updateVariant({
								variables: {
									updatedVariant: {
										id: parseInt(variant.id, 10),
										description: variant.description.trim(),
									},
								},
							}),
						),
				);
			}

			// Create all of the new variants
			await Promise.all(
				variants
					.filter((variant) => variant.isNew && variant.description.trim())
					.map((variant) =>
						createVariant({
							variables: {
								productId: existingProduct.id,
								newVariant: {
									description: variant.description.trim(),
								},
							},
						}),
					),
			);
		} else {
			await createProduct({
				variables: {
					newProduct: {
						name: name.trim(),
						variants: variants
							.filter((variant) => variant.description.trim())
							.map((variant) => ({
								description: variant.description.trim(),
							})),
					},
				},
			});
		}

		onClose(true);
	}, [
		existingProduct,
		onClose,
		name,
		variants,
		createProduct,
		createVariant,
		updateProduct,
		updateVariant,
	]);

	return (
		<Modal isOpen closeButtonLabel='Cancel' onClose={onCancel}>
			<Modal.Header title={existingProduct ? 'Edit Product' : 'New Product'} />
			<Modal.Content>
				<Box mb='space.400'>
					<Text fontSize='200' fontWeight='semibold' color='text.body'>
						Name
					</Text>
					<Input id='name' name='name' value={name} onChange={setName} mt='space.200' />
				</Box>
				<Box>
					<Text fontSize='200' fontWeight='semibold' color='text.body'>
						Variants
					</Text>

					<Box display='flex' flexDirection='column' mt='space.200'>
						{variants.map((variant) => (
							<Input
								id={variant.id}
								name={variant.id}
								value={variant.description}
								mb='space.300'
								elemAfter={
									variant.isNew && (
										<Icon
											name='trash-solid'
											color='button.destructive.background.base'
											cursor='pointer'
											data-variant-id={variant.id}
											onClick={onRemoveVariant}
										/>
									)
								}
								onChange={onUpdateVariant}
								data-variant-id={variant.id}
							/>
						))}
					</Box>
				</Box>
			</Modal.Content>
			<Modal.Footer display='flex' justifyContent='flex-end'>
				<Button mr='space.300' onClick={onCancel}>
					Cancel
				</Button>
				<LoaderButton
					appearance='primary'
					minWidth={75}
					disabled={!saveEnabled}
					isLoading={isLoading}
					onClick={onSave}
				>
					{existingProduct ? 'Save' : 'Create'}
				</LoaderButton>
			</Modal.Footer>
		</Modal>
	);
};

export default ProductEditModal;
